<template>
  <div id="app">
    <div class="header">
      <div class="header-logo"><img src="@/assets/logo.png" alt="" /></div>
      <div class="header-list">
        <div
          class="list-item"
          style="margin-right: 22px"
          @click="$router.push('/')"
        >
          <span style="padding: 0px">首页</span>
          <div class="active"></div>
        </div>
        <div
          class="list-item"
          style="cursor: pointer"
          
        >
          <span>产品</span>
          <div class="active"></div>
          <div class="product">
            <div
              :class="
                productIndex == index
                  ? 'product-item product-active'
                  : 'product-item'
              "
              v-for="(ite, index) in productList"
              :key="index"
              @click.stop="clickProduct(ite, index)"
            >
              {{ ite.name }}
            </div>
          </div>
        </div>
        <div
          class="list-item"
          style="cursor: pointer"
          
        >
          <span>解决方案</span>
          <div class="active"></div>
          <div class="dropdown">
            <div
              :class="
                dropdownIndex == index
                  ? 'dropdown-item dropdown-active'
                  : 'dropdown-item'
              "
              v-for="(item, index) in dropdownList"
              :key="index"
              @click.stop="clickDropdown(item, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="list-item" @click="$router.push('/league')">
          <span>发展联盟</span>
          <div class="active"></div>
        </div>
        <div class="list-item" @click="$router.push('/news')">
          <span>新闻动态</span>
          <div class="active"></div>
        </div>
        <div class="list-item" @click="$router.push('/relation')">
          <span>联系我们</span>
          <div class="active"></div>
        </div>
      </div>
    </div>
    <router-view />
    <div class="footer">
      <div class="footer-item">
        <div class="lt"><img src="@/assets/footerlogo.png" alt="" /></div>
        <div class="mid">
          Copyright © 2016-2022 Beijing xunhong Information Technology Co., Ltd.
          All Rights Reserved.
        </div>
       <div class="beian">
         <a class="rt" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">京ICP备2021016296号</a>
        <a class="rt" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502050815" target="_blank" rel="noopener noreferrer">京公网安备:11010502050815号</a>
       </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //  tabList:[
      //  {name:'首页',link:'/'},
      //  {name:'产品',link:'/Product'},
      //  {name:'解决方案',link:'/scheme'},
      //  {name:'发展联盟',link:'/league'},
      //  {name:'新闻动态',link:''},
      //  {name:'联系我们',link:''}
      //  ],
      dropdownList: [
        { name: "防伪溯源", link: "/anti" },
        { name: "电子政务", link: "/scheme" },
        { name: "版权保护", link: "/copyright" },
        { name: "合同盖章", link: "/contract" },
        { name: "存证", link: "/existing" },
        { name: "其他可探索的服务", link: "/other" },
      ],
      productList: [
        { name: "知行链公网", link: "/gong" },
        { name: "知行链专网 ", link: "/zhuan" },
        { name: "知行链赋能平台", link: "/energize" },
        { name: "知行链生态", link: "/zoology" },
      ],
      tabIndex: 0,
      dropdownIndex: null,
    };
  },
  mounted() {},
  methods: {
    tabClick(item, index) {
      this.tabIndex = index;
      this.$router.push(item.link);
    },
    clickDropdown(item, index) {
      this.dropdownIndex = index;
      this.$router.push(item.link);
    },
    clickProduct(ite, index) {
      this.productIndex = index;
      this.$router.push(ite.link);
    },
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
  height: 88px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 99;
  @media screen and (min-width: 1560px) {
    .header-logo {
      width: 113px;
      height: 33px;
      position: absolute;
      // margin-right: 177px;
      left: 70px;
    }
    .header-list {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      .list-item {
        height: 88px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        cursor: pointer;
        .dropdown {
          position: absolute;
          bottom: -285px;
          width: 156px;
          height: 308px;
          background: rgba(0, 0, 0, 0.86);
          border-radius: 4px;
          padding-top: 16px;
          box-sizing: border-box;
          display: none;
          z-index:999999;
          .dropdown-item {
            width: 100%;
            height: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
            line-height: 45px;
          }
          .dropdown-active {
            color: #19f9fa;
          }
        }
        .product {
          position: absolute;
          bottom: -185px;
          width: 156px;
          height: 208px;
          background: rgba(0, 0, 0, 0.86);
          border-radius: 4px;
          padding-top: 16px;
          box-sizing: border-box;
          display: none;
        }
        .product-item {
          width: 100%;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 45px;
        }
        .product-active {
          color: #19f9fa;
        }
        span {
          display: block;
          height: 35px;
          padding: 0 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          letter-spacing: 2px;
        }

        .act {
          color: #19f9fa;
          height: 33px !important;
        }
        .active {
          width: 22px;
          height: 2px;
          background: #19f9fa;
          border-radius: 1px;
          display: none;
        }
      }
      .list-item:hover span {
        color: #19f9fa;
        height: 33px !important;
      }
      .list-item:hover .active {
        display: block;
      }
      .list-item:hover .dropdown {
        display: block;
      }
      .list-item:hover .product {
        display: block;
      }
    }
  }
}
.footer {
  width: 100%;
  height: 100px;
  background: #061931;
  .footer-item {
    width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    .lt {
      width: 111px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .mid {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 1px;
      margin-left: 32px;
    }
    .rt {
      display: flex;
      margin-left: 50px;
      align-items: center;
      text-decoration: none;
      img {
        width: 20px;
        height: 20px;
      }
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 1px;
    }
  }
}
@media screen and (max-width: 1560px) {
  .header-logo {
    width: 113px;
    height: 33px;
    margin-right: 57px;
    margin-left: 70px;
  }
  .header-list {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    .list-item {
      height: 88px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      cursor: pointer;
      .dropdown {
        position: absolute;
        bottom: -285px;
        width: 156px;
        height: 308px;
        background: rgba(0, 0, 0, 0.86);
        border-radius: 4px;
        padding-top: 16px;
        box-sizing: border-box;
        display: none;
        .dropdown-item {
          width: 100%;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 45px;
        }
        .dropdown-active {
          color: #19f9fa;
        }
      }
      .product {
        position: absolute;
        bottom: -185px;
        width: 156px;
        height: 208px;
        background: rgba(0, 0, 0, 0.86);
        border-radius: 4px;
        padding-top: 16px;
        box-sizing: border-box;
        display: none;
        .product-item {
          width: 100%;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 45px;
        }
        .product-active {
          color: #19f9fa;
        }
      }
      span {
        display: block;
        height: 35px;
        padding: 0 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        letter-spacing: 2px;
      }

      .act {
        color: #19f9fa;
        height: 33px !important;
      }
      .active {
        width: 22px;
        height: 2px;
        background: #19f9fa;
        border-radius: 1px;
        display: none;
      }
    }
    .list-item:hover span {
      color: #19f9fa;
      height: 33px !important;
    }
    .list-item:hover .active {
      display: block;
    }
    .list-item:hover .dropdown {
      display: block;
    }
    .list-item:hover .product {
      display: block;
    }
  }
}
</style>
