import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
   {
     path: '/scheme',
     name: 'scheme',
     component: () => import('../views/scheme.vue')
   },
   {
    path: '/Product',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/league',
    name: 'league',
    component: () => import('../views/league.vue')
  },  {
    path: '/anti',
    name: 'anti',
    component: () => import('../views/anti.vue')
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: () => import('../views/copyright.vue')
  },
  {
    path: '/contract',
    name: 'contract',
    component: () => import('../views/contract.vue')
  },
  {
    path: '/existing',
    name: 'existing',
    component: () => import('../views/existing.vue')
  },
  {
    path: '/other',
    name: 'other',
    component: () => import('../views/other.vue')
  },
  {
    path: '/gong',
    name: 'gong',
    component: () => import('../views/gong.vue')
  },
  {
    path: '/zhuan',
    name: 'zhuan',
    component: () => import('../views/zhuan.vue')
  },
  {
    path: '/energize',
    name: 'energize',
    component: () => import('../views/energize.vue')
  },
  {
    path: '/zoology',
    name: 'zoology',
    component: () => import('../views/zoology.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    path: '/newsMain',
    name: 'newsMain',
    component: () => import('../views/newsMain.vue')
  },
  {
    path: '/relation',
    name: 'relation',
    component: () => import('../views/relation.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
