var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"header-list"},[_c('div',{staticClass:"list-item",staticStyle:{"margin-right":"22px"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('span',{staticStyle:{"padding":"0px"}},[_vm._v("首页")]),_c('div',{staticClass:"active"})]),_c('div',{staticClass:"list-item",staticStyle:{"cursor":"pointer"}},[_c('span',[_vm._v("产品")]),_c('div',{staticClass:"active"}),_c('div',{staticClass:"product"},_vm._l((_vm.productList),function(ite,index){return _c('div',{key:index,class:_vm.productIndex == index
                ? 'product-item product-active'
                : 'product-item',on:{"click":function($event){$event.stopPropagation();return _vm.clickProduct(ite, index)}}},[_vm._v(" "+_vm._s(ite.name)+" ")])}),0)]),_c('div',{staticClass:"list-item",staticStyle:{"cursor":"pointer"}},[_c('span',[_vm._v("解决方案")]),_c('div',{staticClass:"active"}),_c('div',{staticClass:"dropdown"},_vm._l((_vm.dropdownList),function(item,index){return _c('div',{key:index,class:_vm.dropdownIndex == index
                ? 'dropdown-item dropdown-active'
                : 'dropdown-item',on:{"click":function($event){$event.stopPropagation();return _vm.clickDropdown(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.$router.push('/league')}}},[_c('span',[_vm._v("发展联盟")]),_c('div',{staticClass:"active"})]),_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.$router.push('/news')}}},[_c('span',[_vm._v("新闻动态")]),_c('div',{staticClass:"active"})]),_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.$router.push('/relation')}}},[_c('span',[_vm._v("联系我们")]),_c('div',{staticClass:"active"})])])]),_c('router-view'),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-logo"},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-item"},[_c('div',{staticClass:"lt"},[_c('img',{attrs:{"src":require("@/assets/footerlogo.png"),"alt":""}})]),_c('div',{staticClass:"mid"},[_vm._v(" Copyright © 2016-2022 Beijing xunhong Information Technology Co., Ltd. All Rights Reserved. ")]),_c('div',{staticClass:"beian"},[_c('a',{staticClass:"rt",attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("京ICP备2021016296号")]),_c('a',{staticClass:"rt",attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502050815","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("京公网安备:11010502050815号")])])])])
}]

export { render, staticRenderFns }