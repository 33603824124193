import Vue from 'vue';
import {
  Button,
  Carousel,
  CarouselItem
} from 'element-ui';

Vue.use(Button);
Vue.use(Carousel);
Vue.use(CarouselItem);

  


